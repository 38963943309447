html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: -apple-system, 'Roboto', 'SF-Pro-Display-Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-height: 100vh;
}
div,
p,
span {
  font-size: 14px;
}

@font-face {
  font-family: 'SF-Pro-Display-Black';
  src: url('./assets/fonts/SF-Pro-Display-Black.otf') format('opentype'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'SF-Pro-Display-Bold';
  src: url('./assets/fonts/SF-Pro-Display-Bold.otf') format('opentype'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'SF-Pro-Display-Heavy';
  src: url('./assets/fonts/SF-Pro-Display-Heavy.otf') format('opentype'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'SF-Pro-Display-Light';
  src: url('./assets/fonts/SF-Pro-Display-Light.otf') format('opentype'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'SF-Pro-Display-Medium';
  src: url('./assets/fonts/SF-Pro-Display-Medium.otf') format('opentype'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'SF-Pro-Display-Regular';
  src: url('./assets/fonts/SF-Pro-Display-Regular.otf') format('opentype'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'SF-Pro-Display-Semibold';
  src: url('./assets/fonts/SF-Pro-Display-Semibold.otf') format('opentype'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'SF-Pro-Display-Thin';
  src: url('./assets/fonts/SF-Pro-Display-Thin.otf') format('opentype'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'SF-Pro-Display-Ultralight';
  src: url('./assets/fonts/SF-Pro-Display-Ultralight.otf') format('opentype'); /* IE9 Compat Modes */
}

.ant-form-item {
  margin-bottom: 0 !important;
}
.ql-editor {
  max-height: 20rem;
  overflow: scroll;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  height: 15.75rem;
}

.ant-form-item-has-error .ant-form-item-explain,
.ant-form-item-has-error .ant-form-item-split {
  height: 0;
  min-height: 0;
}
.ant-select-dropdown {
  position: relative;
  z-index: 100000000;
}

.ant-table-thead > tr > th {
  padding: 1px 16px !important;
}
.ant-table-column-sorters {
  padding: 10px 0px !important;
}

.ant-table-row-selected td {
  background: #e6f7ff !important;
}
.ant-table-row-selected.row-different td {
  background: #c8edff !important;
}

.ant-tooltip-inner {
  white-space: pre-wrap;
}
.ant-modal-body {
  display: flex;
  flex-direction: column;
  align-items: self-start;
}